<template>
    <div class="login-wrapper">
        <div class="login-box">
            <h2>学会项目执行系统</h2>
            <div class="login-info">
                <div class="flex-center login-type">
                    <h3 @click="loginType=1" :class="{active:loginType === 1}">登录</h3>
                    <!--<h3 @click="loginType=2" :class="{active:loginType === 2}">手机号登录</h3>-->
                </div>
                <div v-if="loginType === 1">
                    <div class="login-input flex-center">
                        <i class="iconfont iconyouhuiquanguanli-"></i>
                        <el-input v-model="account" maxlength="11" placeholder="请输入登录账号" class="login-item"></el-input>
                    </div>
                    <div class="login-input flex-center">
                        <i class="iconfont iconyuechi"></i>
                        <el-input type="password" v-model="password" placeholder="请输入登录密码" show-password
                                  class="login-item"></el-input>
                    </div>
                    <div class="login-input flex-center">
                        <i class="iconfont iconanquan"></i>
                        <el-input v-model="code" placeholder="请输入验证码" class="login-item"></el-input>
                        <div class="code-img" @click="refreshCode">
                            <Sidentify :identifyCode="identifyCode"></Sidentify>
                        </div>
                    </div>
                    <el-button type="primary" class="login-submit-btn" @click="login" :disabled="submitState">登 录
                    </el-button>
                    <div class="to-setpass">
                        <router-link to="/forget" class="forget">
                            忘记密码？立即修改
                        </router-link>
                    </div>

                </div>
                <div v-if="loginType === 2">
                    <div class="login-input flex-center">
                        <i class="iconfont iconyouhuiquanguanli-"></i>
                        <el-input v-model="account" placeholder="请输入手机号" class="login-item"></el-input>
                    </div>
                    <div class="login-input flex-center">
                        <i class="iconfont iconanquan"></i>
                        <el-input v-model="code" placeholder="请输入验证码" class="login-item"></el-input>
                        <div class="code-text" @click="sendCode">
                            {{codeText}} <span v-if="typeof codeText === 'number'">秒后重新发送</span>
                        </div>
                    </div>
                    <el-button type="primary" class="login-submit-btn" @click="login2" :disabled="submitState">登 录
                    </el-button>
                </div>
            </div>
        </div>
        <div class="footer">北京乳腺病防治学会、北京癌症防治学会 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19017650号-1</a></div>
    </div>
</template>

<script>
    import Sidentify from '../components/identify'

    export default {
        name: "login",
        data() {
            return {
                loginType: 1,
                account: '',
                password: '',
                code: '',
                identifyCode: '',
                identifyCodes: '123456789ABCDEFGHJKLMNPQRSTUVWXYZ',
                submitState: false,
                codeText: '获取验证码', // 验证码文字
                id: null, // 计时器id
                timeId: null, // 一次性计时器id
            }
        },
        created() {
            this.refreshCode()
        },
        methods: {
            login() {
                this.submitState = true
                if (!this.account) {
                    this.$message.error('请输入帐号')
                    this.submitState = false
                    return
                }
                if (!this.password) {
                    this.$message.error('请输入登录密码')
                    this.submitState = false
                    return
                }
               if (!this.code) {
                   this.$message.error('请输入验证码')
                   this.submitState = false
                   return
               }
               if (this.code.toUpperCase() !== this.identifyCode) {
                   this.$message.error('验证码错误，请重新输入')
                   this.submitState = false
                   return
               }
                this.$api.apiContent.passLogin({
                    phone: this.account,
                    pass: this.password,
                }).then(res => {
                    this.$store.state.user = res.data
                    this.$message({
                        message: '登录成功',
                        type: 'success',
                        duration: 2000
                    });
                    setTimeout(() => {
                        this.$router.push({
                            path: '/home'
                        })
                    }, 500)
                }).catch(e => {
                    this.$message.error(e.msg)
                    this.submitState = false
                })
            },
            login2() {
                this.submitState = true
                if (!this.account) {
                    this.$message.error('请输入手机号')
                    this.submitState = false
                    return
                }
                if (this.$tools.checkTel(this.account)) {
                    this.$message.error('手机号格式错误，请确认')
                    this.submitState = false
                    return
                }
                if (!this.code) {
                    this.$message.error('请输入验证码')
                    this.submitState = false
                    return
                }
                this.$api.apiContent.codeLogin({
                    phone: this.account,
                    code: this.code,
                }).then(res => {
                    this.$store.state.user = res.data
                    this.$message({
                        message: '登录成功',
                        type: 'success',
                        duration: 2000
                    });
                    setTimeout(() => {
                        this.$router.push({
                            path: '/home'
                        })
                    }, 500)

                }).catch(e => {
                    this.$message.error(e.msg)
                    this.submitState = false
                })
            },
            //  获取验证码
            sendCode() {
                if (!this.account) {
                    this.$message.error('请输入手机号')
                    this.submitState = false
                    return
                }
                if (this.$tools.checkTel(this.account)) {
                    this.$message.error('手机号格式错误，请确认')
                    this.submitState = false
                    return
                }
                if (this.codeText === '获取验证码' || this.codeText === '重新发送') {
                    this.codeText = 60
                    this.id = setInterval(() => {
                        if (this.codeText === 0) {
                            this.codeText = '重新发送'
                            return
                        }
                        if (typeof this.codeText === 'number') {
                            this.codeText--
                        }
                    }, 1000)
                    this.$api.apiContent.sendMsg({
                        phone: this.account,
                        type: 1
                    }).then().catch(e => {
                        this.$message.error(e.info)
                        this.codeText = '重新发送'
                        clearInterval(this.id)
                    })
                }
            },

            /*
            * 生成验证码
            * */
            randomNum(min, max) {
                return Math.floor(Math.random() * (max - min) + min)
            },
            refreshCode() {
                this.identifyCode = ''
                this.makeCode(this.identifyCode, 4)
            },
            makeCode(o, l) {
                for (let i = 0; i < l; i++) {
                    this.identifyCode += this.identifyCodes[
                        this.randomNum(0, 33)
                        ]
                }
            }
        },
        components: {
            Sidentify
        },
        watch: {
            loginType() {
                this.code = ''
                this.refreshCode()
            }
        }
    }
</script>

<style lang="scss">
    .login-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-top: 0;
        width: 100%;
        background: url(../assets/img/login.jpg) no-repeat center;

        .login-box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 700px;

            h2 {
                font-size: 54px;
                color: #fff;
                text-align: center;
                margin-bottom: 80px;
            }

            .login-info {
                width: 100%;
                padding: 30px 100px 50px;
                background: #fff;
                border-radius: 10px;
                .login-type {
                    width: 300px;
                    margin: 0 auto;
                    justify-content: center;
                    h3 {
                        display: block;
                        width: 150px;
                        text-align: center;
                        font-size: 24px;
                        line-height: 36px;
                        margin-bottom: 30px;
                        cursor: pointer;
                        position: relative;
                        &.active {
                            color: #3b77e7;
                            &::after {
                                position: absolute;
                                left: 30%;
                                bottom: -5px;
                                content: '';
                                width: 40%;
                                height: 2px;
                                background: #3b77e7;
                            }
                        }
                    }
                }

                .login-input {
                    height: 60px;
                    background-color: #fbfbfb;
                    border-radius: 4px;
                    border: solid 1px #e6e6e6;
                    margin-bottom: 20px;

                    i {
                        display: block;
                        width: 60px;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 500;
                    }

                    .login-item {
                        flex: 1;

                        input {
                            padding-left: 0;
                            background: transparent;
                            border: 0 !important;
                        }
                    }

                    .code-img {
                        width: 116px;
                        height: 41px;
                        background: #fff;
                        margin: 0 20px;
                    }
                    .code-text {
                        width: 116px;
                        height: 41px;
                        text-align: center;
                        line-height: 41px;
                        color: #3b77e7;
                        margin: 0 20px;
                        cursor: pointer;
                    }
                }

                .login-submit-btn {
                    width: 100%;
                    height: 62px;
                    background-color: #3b77e7;
                    border-radius: 6px;
                    font-size: 21px;
                    letter-spacing: 10px;
                }
                .to-setpass {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 10px;
                    .forget {
                        color: #3b77e7;
                    }
                }
            }
        }
        .footer{
            position: absolute;
            color:#ffffff;
            bottom: 2%;
            width: 100%;
            text-align: center;
            a{
                color:#ffffff;
            }
        }
    }
</style>